<template>
<div>
  <h2>Your Projects</h2>

  <div v-if="loading" class="loading">Loading projects...</div>

  <div v-else>
    <!-- Sorting Section -->
    <div class="sort-section">
      <label for="sort">Sort by:</label>
      <select v-model="selectedSortOption" @change="sortProjects">
        <option value="newest">Newest to Oldest</option>
        <option value="oldest">Oldest to Newest</option>
        <option value="az">A-Z</option>
        <option value="za">Z-A</option>
      </select>
    </div>

    <!-- Projects Section -->
    <h3>Projects You're a Member Of</h3>
    <div v-if="projects.length === 0" class="no-projects">
      No projects found.
    </div>
    
    <ul class="project-list" v-else>
      <li v-for="project in projects" :key="project.id">
        {{ project.project_name }}

        <!-- Show Edit/Delete/Duplicate buttons -->
        <div>
          <!-- Edit button with pencil icon -->
          <button @click="renameProject(project.id)">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <!-- Delete button with trash bin icon -->
          <button @click="confirmDeleteProject(project.id)">
            <i class="fas fa-trash-alt"></i>
          </button>

          <!-- Duplicate button -->
          <button @click="duplicateProject(project)">
            Duplicate
          </button>
        </div>

        <!-- Link to Project Detail page -->
        <router-link 
          :to="{ name: 'ProjectDetail', params: { id: project.id } }" 
          @click.native="selectProject(project)"
          class="open-project-button">
          Open
        </router-link>
      </li>
    </ul>

    <!-- New Project Form (only for authenticated users) -->
    <div class="new-project-form" v-if="userStore.isAuthenticated">
      <h3>Create a New Project</h3>
      <input v-model="newProjectName" placeholder="Enter Project Name" />
      <button @click="addProject">Add Project</button>
    </div>
  </div>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { supabase } from '../supabase';
import { useUserStore } from '../stores/userStore';

export default {
setup() {
  const projects = ref([]);
  const newProjectName = ref('');
  const loading = ref(true);
  const userStore = useUserStore();
  const selectedSortOption = ref(localStorage.getItem('selectedSortOption') || 'newest'); // Load saved sort option or default to newest

  // Fetch the projects where the user is a member
  const fetchUserProjects = async () => {
    const cachedProjects = localStorage.getItem('userProjects'); // Check if projects are cached

    if (cachedProjects) {
      // Load projects from localStorage
      projects.value = JSON.parse(cachedProjects);
      loading.value = false;
      sortProjects(); // Apply sorting from localStorage data
      return;
    }

    loading.value = true;
    try {
      const userEmail = userStore.getUserEmail;

      // Fetch project_ids from project_members where the user is a member
      const { data: projectMemberships, error: memberError } = await supabase
        .from('project_members')
        .select('project_id')
        .eq('user_email', userEmail);

      if (memberError) throw new Error('Error fetching project memberships.');

      const projectIds = projectMemberships.map((member) => member.project_id);

      if (projectIds.length > 0) {
        const { data: projectData, error: projectError } = await supabase
          .from('projects')
          .select('*')
          .in('id', projectIds);

        if (projectError) throw new Error('Error fetching project details.');

        projects.value = projectData || [];
        localStorage.setItem('userProjects', JSON.stringify(projects.value)); // Cache projects in localStorage
        sortProjects(); // Sort after fetching
      } else {
        projects.value = [];
      }
    } catch (error) {
      console.error('Error loading projects:', error.message);
    } finally {
      loading.value = false;
    }
  };

  // Sort the projects and save the selected sort option in localStorage
  const sortProjects = () => {
    if (selectedSortOption.value === 'az') {
      projects.value.sort((a, b) => a.project_name.localeCompare(b.project_name));
    } else if (selectedSortOption.value === 'za') {
      projects.value.sort((a, b) => b.project_name.localeCompare(a.project_name));
    } else if (selectedSortOption.value === 'newest') {
      projects.value.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (selectedSortOption.value === 'oldest') {
      projects.value.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    }
    localStorage.setItem('selectedSortOption', selectedSortOption.value); // Save sort option to localStorage
  };

  // Create a new project
  const addProject = async () => {
    if (newProjectName.value.trim()) {
      try {
        if (!userStore.isAuthenticated) {
          alert('You must be logged in to create a project.');
          return;
        }

        const { data: projectData, error: projectError } = await supabase
          .from('projects')
          .insert([
            {
              project_name: newProjectName.value.trim(),
              owner_email: userStore.getUserEmail,
            },
          ])
          .select();

        if (projectError) throw new Error('Error adding new project');

        const newProject = projectData[0];

        const { error: memberError } = await supabase
          .from('project_members')
          .insert([
            {
              project_id: newProject.id,
              user_email: userStore.getUserEmail,
              role: 'owner',
            },
          ]);

        if (memberError) throw new Error('Error adding user to project_members as owner');

        projects.value.push(newProject);
        newProjectName.value = '';
        localStorage.setItem('userProjects', JSON.stringify(projects.value)); // Update cached projects in localStorage
        sortProjects(); // Sort after adding new project
      } catch (error) {
        console.error('Error creating project:', error.message);
      }
    } else {
      alert('Project name cannot be empty.');
    }
  };

  // Rename an existing project
  const renameProject = async (projectId) => {
    const newName = prompt('Enter new project name:');
    if (newName && newName.trim()) {
      try {
        const { error } = await supabase
          .from('projects')
          .update({ project_name: newName.trim() })
          .eq('id', projectId);

        if (error) throw error;

        const project = projects.value.find((p) => p.id === projectId);
        if (project) project.project_name = newName.trim();
        localStorage.setItem('userProjects', JSON.stringify(projects.value)); // Update cached projects in localStorage
      } catch (error) {
        console.error('Error renaming project:', error.message);
      }
    }
  };

  // Multi-step confirmation before deleting a project
  const confirmDeleteProject = async (projectId) => {
    const step1 = confirm('Are you sure you want to delete this project?');
    if (!step1) return;

    const step2 = confirm('This will PERMANENTLY delete the project and its data.');
    if (!step2) return;

    const step3 = confirm('3 strikes and your project is out of here! No way to recover this information!');
    if (!step3) return;

    try {
      const { error } = await supabase
        .from('projects')
        .delete()
        .eq('id', projectId);

      if (error) {
        console.error('Error deleting project:', error);
        throw new Error('Error deleting project: ' + error.message);
      }

      // Remove the deleted project from the local list
      projects.value = projects.value.filter((p) => p.id !== projectId);
      localStorage.setItem('userProjects', JSON.stringify(projects.value)); // Update cached projects in localStorage
      alert('Project deleted successfully!');
    } catch (error) {
      console.error('Error deleting project:', error.message);
      alert('Failed to delete the project. ' + error.message);
    }
  };

  // Duplicate an existing project
  const duplicateProject = async (originalProject) => {
    try {
      const { data: newProjectData, error: projectError } = await supabase
        .from('projects')
        .insert([
          {
            project_name: originalProject.project_name + ' (Copy)',
            owner_email: userStore.getUserEmail,
          },
        ])
        .select();

      if (projectError) throw new Error('Error duplicating project');

      const newProject = newProjectData[0];

      // Duplicate project members
      const { data: projectMembers, error: memberError } = await supabase
        .from('project_members')
        .select('*')
        .eq('project_id', originalProject.id);

      if (memberError) throw new Error('Error fetching original project members');

      const newMembers = projectMembers.map((member) => ({
        project_id: newProject.id,
        user_email: member.user_email,
        role: member.role,
      }));

      const { error: addMembersError } = await supabase.from('project_members').insert(newMembers);

      if (addMembersError) throw new Error('Error adding members to duplicated project');

      // Duplicate quickfire buttons
      const { data: quickfireButtons, error: quickfireError } = await supabase
        .from('quickfire_buttons')
        .select('*')
        .eq('project_id', originalProject.id);

      if (quickfireError) throw new Error('Error fetching original quickfire buttons');

      const newQuickfireButtons = quickfireButtons.map((button) => ({
        project_id: newProject.id,
        name: button.name,
        note: button.note,
        color: button.color,
      }));

      const { error: addButtonsError } = await supabase
        .from('quickfire_buttons')
        .insert(newQuickfireButtons);

      if (addButtonsError) throw new Error('Error adding quickfire buttons to duplicated project');

      projects.value.push(newProject);
      localStorage.setItem('userProjects', JSON.stringify(projects.value)); // Update cached projects in localStorage
      sortProjects(); // Sort after duplicating project

      alert('Project duplicated successfully!');
    } catch (error) {
      console.error('Error duplicating project:', error.message);
      alert('An error occurred while duplicating the project.');
    }
  };

  // Select a project to view its details
  const selectProject = (project) => {
    userStore.setCurrentProject(project);
  };

  // Fetch user projects on component mount
  onMounted(fetchUserProjects);

  return {
    projects,
    newProjectName,
    loading,
    addProject,
    renameProject,
    confirmDeleteProject,
    duplicateProject,
    selectProject,
    sortProjects,
    selectedSortOption,
    userStore,
  };
},
};
</script>

<style scoped>
.projects-container {
max-width: 600px;
margin: 50px auto;
padding: 20px;
background-color: #f9f9f9;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.project-list {
list-style: none;
padding: 0;
}

.project-list li {
display: flex;
justify-content: space-between;
padding: 10px 0;
border-bottom: 1px solid #ddd;
}

button {
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
padding: 5px 10px;
cursor: pointer;
margin-left: 10px;
}

button:hover {
background-color: #0056b3;
}

button i {
font-size: 16px;
}

.loading,
.no-projects {
text-align: center;
color: #007bff;
margin: 20px 0;
}

.open-project-button {
background-color: #28a745;
color: white;
padding: 5px 10px;
border-radius: 4px;
text-decoration: none;
}

.open-project-button:hover {
background-color: #218838;
}

.sort-section {
margin-bottom: 20px;
display: flex;
align-items: center;
}

.sort-section select {
margin-left: 10px;
padding: 5px;
border-radius: 4px;
}
</style>
