<!-- src/components/TimeSourceSelector.vue -->
<template><center>
<div class="time-source-selector">
  <label for="timeSource">Select Time Source:</label>
  <select id="timeSource" v-model="selectedSource" @change="onSourceChange">
    <option value="device">Device Time</option>
    <option value="custom">Custom Timecode</option>
    <option value="world">World Time (GMT)</option>
  </select>

  <!-- Custom Timecode Input -->
  <div v-if="selectedSource === 'custom'" class="custom-timecode-input">
    <label for="customTime">Initial Time (HH:MM:SS):</label>
    <input
      type="text"
      id="customTime"
      v-model="customTime"
      placeholder="00:00:00"
      pattern="^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$"
      title="Please enter time in HH:MM:SS format."
    />
    <button @click="setCustomTime" :disabled="!isValidCustomTime">Set Custom Timecode</button>
  </div>

  <!-- World Time Offset Input -->
  <div v-if="selectedSource === 'world'" class="world-time-offset-input">
    <label for="gmtOffset">GMT Offset (hours):</label>
    <input
      type="number"
      id="gmtOffset"
      v-model.number="gmtOffset"
      placeholder="e.g., +2 or -5"
    />
    <button @click="setWorldTime">Set World Time (GMT)</button>
  </div>
</div></center>
</template>

<script>
import { ref, computed } from 'vue';
import { useUserStore } from '../stores/userStore';

export default {
setup() {
  const userStore = useUserStore();
  const selectedSource = ref(userStore.currentTimeSource);
  const customTime = ref('00:00:00');
  const gmtOffset = ref(0);

  // Validation for custom timecode input
  const isValidCustomTime = computed(() => {
    const timeRegex = /^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return timeRegex.test(customTime.value);
  });

  const onSourceChange = () => {
    userStore.setCurrentTimeSource(selectedSource.value);
  };

  const setCustomTime = () => {
    if (!isValidCustomTime.value) {
      alert('Please enter a valid time in HH:MM:SS format.');
      return;
    }
    userStore.setLiveTimecode(customTime.value);
    // Optionally, reset customTime input
    customTime.value = '';
  };

  const setWorldTime = () => {
    userStore.startWorldTime(gmtOffset.value);
    // Optionally, reset GMT offset input
    gmtOffset.value = 0;
  };

  return {
    selectedSource,
    customTime,
    gmtOffset,
    onSourceChange,
    setCustomTime,
    setWorldTime,
    isValidCustomTime,
  };
},
};
</script>

<style scoped>
.time-source-selector {
margin-top: 20px;
display: flex;
flex-direction: column;
align-items: center;
}

.time-source-selector label {
margin-right: 10px;
font-weight: bold;
}

.time-source-selector select {
padding: 5px;
border-radius: 4px;
margin-bottom: 10px;
}

.custom-timecode-input,
.world-time-offset-input {
margin-top: 10px;
display: flex;
flex-direction: column;
align-items: center;
}

.custom-timecode-input label,
.world-time-offset-input label {
margin-bottom: 5px;
font-weight: bold;
}

.custom-timecode-input input,
.world-time-offset-input input {
padding: 5px;
border-radius: 4px;
width: 150px;
margin-bottom: 5px;
text-align: center;
}

.custom-timecode-input button,
.world-time-offset-input button {
padding: 5px 10px;
border: none;
border-radius: 4px;
background-color: #28a745; /* Bootstrap Success */
color: white;
cursor: pointer;
}

.custom-timecode-input button:disabled {
background-color: #6c757d; /* Bootstrap Secondary */
cursor: not-allowed;
}

.custom-timecode-input button:hover:not(:disabled),
.world-time-offset-input button:hover {
background-color: #218838;
}
</style>
