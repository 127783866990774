import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '../stores/userStore';

// Lazy load components
import Projects from '../components/Projects.vue';
const ProjectDetail = () => import('../components/ProjectDetail.vue');
const ProjectNotes = () => import('../components/ProjectNotes.vue');
const LocationNotes = () => import('../components/LocationNotes.vue');
const ProjectSettings = () => import('../components/ProjectSettings.vue');
const ProjectQuickfire = () => import('../components/ProjectQuickfire.vue');
const ProjectLocations = () => import('../components/ProjectLocations.vue');
const ProjectSchedule = () => import('../components/ProjectSchedule.vue');
const ProjectPatchBay = () => import('../components/ProjectPatchBay.vue');
const MicMapper = () => import('../components/MicMapper.vue');
const ProjectGear = () => import('../components/ProjectGear.vue');
const ProjectContacts = () => import('../components/ProjectContacts.vue');
const LocationSelector = () => import('../components/LocationSelector.vue');
const CallSheet = () => import('../components/CallSheet.vue'); // Add CallSheet.vue
const Login = () => import('../components/Login.vue');
const ConfirmEmail = () => import('../components/ConfirmEmail.vue');
const SetPassword = () => import('../components/SetPassword.vue');

// Define routes
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
  },
  {
    path: '/projects/:id',
    name: 'ProjectDetail',
    component: ProjectDetail,
    props: true, // Pass the project ID as a prop
    children: [
      {
        path: 'notes',
        name: 'ProjectNotes',
        component: ProjectNotes,
      },
      {
        path: 'quickfire',
        name: 'ProjectQuickfire',
        component: ProjectQuickfire,
      },
      {
        path: 'settings',
        name: 'ProjectSettings',
        component: ProjectSettings,
      },
      {
        path: 'locations',
        name: 'ProjectLocations',
        component: ProjectLocations,
      },
      {
        path: 'locations/:locationId/notes',
        name: 'LocationNotes',
        component: LocationNotes,
        props: (route) => ({
          locationId: route.params.locationId, // Location ID passed from ProjectNotes.vue
        }),
      },
      {
        path: 'schedule',
        name: 'ProjectSchedule',
        component: ProjectSchedule,
      },
      {
        path: 'patchbay/select-location',
        name: 'LocationSelector',
        component: LocationSelector,
      },
      {
        path: 'patchbay/venues/:venueId/locations/:locationId',
        name: 'LocationPatchBay',
        component: ProjectPatchBay,
        props: true,
      },
      {
        path: 'patchbay',
        name: 'ProjectPatchBay',
        component: ProjectPatchBay,
      },
      {
        path: 'micmapper',
        name: 'MicMapper',
        component: MicMapper,
      },
      {
        path: 'gear',
        name: 'ProjectGear',
        component: ProjectGear,
      },
      {
        path: 'contacts',
        name: 'ProjectContacts',
        component: ProjectContacts,
      },
      {
        path: 'call-sheet',
        name: 'CallSheet',
        component: CallSheet,
        props: (route) => ({ projectId: route.params.id }), // Pass projectId to CallSheet.vue
      },
    ],
  },
  {
    path: '/auth/confirm',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
  },
  {
    path: '/auth/set-password',
    name: 'SetPassword',
    component: SetPassword,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

// Router setup
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation Guard
router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const publicPages = ['ConfirmEmail', 'SetPassword', 'Login'];

  if (!userStore.user && !userStore.currentProject) {
    const user = await userStore.fetchUserSession();

    if (!user) {
      const email = userStore.getUserEmail;
      const projectId = to.params.id; // Ensure we're using the project ID from the route

      if (email && projectId) {
        try {
          const member = await userStore.checkProjectMember(email, projectId);
          if (member) {
            userStore.setCurrentProject({ id: projectId, role: member.role }); // Set the current project
            return next(); // Allow the route
          }
        } catch (error) {
          console.error('Error checking project membership:', error);
        }
      }
    }
  }

  if (publicPages.includes(to.name) || userStore.isAuthenticated || userStore.getCurrentProject) {
    return next();
  }

  next('/'); // Redirect to login if no project or user is available
});

export default router;
