// src/stores/userStore.js
import { defineStore } from 'pinia';
import { supabase } from '../supabase';
import CryptoJS from 'crypto-js'; // For encrypting and decrypting localStorage data

// Retrieve the encryption key from environment variables
const ENCRYPTION_KEY = process.env.VUE_APP_ENCRYPTION_KEY;

if (!ENCRYPTION_KEY) {
  throw new Error('Encryption key not set in environment variables. Please set VUE_APP_ENCRYPTION_KEY.');
}

const SESSION_EXPIRATION_TIME = 3600000; // 1 hour in milliseconds

export const useUserStore = defineStore('userStore', {
  state: () => ({
    // **User and Session State**
    user: null, // Authenticated user from Supabase
    userEmail: null, // Store user's email for both authenticated and non-authenticated users
    currentProject: null, // Store the currently selected project
    liveTimecode: localStorage.getItem('liveTimecode') || '00:00:00', // Live timecode, default to 00:00:00

    // **Contact Management State**
    contacts: [], // List of contacts associated with the current project
    isLoading: false, // Indicates if contacts are being fetched
    isSubmitting: false, // Indicates if a contact is being added, updated, or deleted

    // **Time Source Management State**
    currentTimeSource: localStorage.getItem('currentTimeSource') || 'device', // 'device', 'custom', 'world'

    // **Online Status State**
    onlineStatus: navigator.onLine, // Track online/offline status

    // **Private State**
    _intervalId: null, // To manage the time interval, private
  }),

  actions: {
    // **Online Status Management**
    initializeOnlineStatus() {
      // Add event listeners for online and offline events
      window.addEventListener('online', this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
    },

    updateOnlineStatus() {
      this.onlineStatus = navigator.onLine;
      console.log(`Online status updated: ${this.onlineStatus ? 'Online' : 'Offline'}`);
    },

    cleanupOnlineStatus() {
      // Remove event listeners when no longer needed
      window.removeEventListener('online', this.updateOnlineStatus);
      window.removeEventListener('offline', this.updateOnlineStatus);
    },

    // **Encryption and Decryption**
    encryptData(data) {
      return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
    },

    decryptData(encryptedData) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } catch (e) {
        console.error('Failed to decrypt data:', e);
        return null;
      }
    },

    // **Session Management with Expiration**
    saveSessionToLocalStorage(userData) {
      const sessionData = {
        user: this.encryptData(userData),
        timestamp: new Date().getTime(),
      };
      localStorage.setItem('userSession', JSON.stringify(sessionData));
    },

    loadSessionFromLocalStorage() {
      const storedSession = localStorage.getItem('userSession');
      if (!storedSession) return null;

      const sessionData = JSON.parse(storedSession);
      const currentTime = new Date().getTime();

      if (currentTime - sessionData.timestamp > SESSION_EXPIRATION_TIME) {
        this.clearSession();
        console.log('Session expired. User has been logged out.');
        return null;
      }

      const decryptedUser = this.decryptData(sessionData.user);
      this.user = decryptedUser;
      return decryptedUser;
    },

    saveProjectToLocalStorage(projectData) {
      localStorage.setItem('currentProject', this.encryptData(projectData));
    },

    loadProjectFromLocalStorage() {
      const storedProject = localStorage.getItem('currentProject');
      if (storedProject) {
        this.currentProject = this.decryptData(storedProject);
      }
    },

    // **User and Session Actions**
    async fetchUserSession() {
      try {
        const {
          data: { session },
          error,
        } = await supabase.auth.getSession();
        if (error) {
          console.error('Error fetching session:', error.message);
          return null;
        }

        // If session exists, it's an authenticated user
        if (session) {
          this.user = session.user;
          this.userEmail = session.user.email; // Store authenticated user's email
          this.saveSessionToLocalStorage(session.user); // Save session to localStorage
          return this.user;
        } else {
          this.clearSession(); // Clear session if no session exists
          return null;
        }
      } catch (error) {
        console.error('Error fetching user session:', error.message);
        this.clearSession();
        return null;
      }
    },

    async checkProjectMember(email, projectId) {
      try {
        const { data, error } = await supabase
          .from('project_members')
          .select('*')
          .eq('user_email', email)
          .eq('project_id', projectId)
          .single();

        if (error || !data) {
          console.error('Error finding project member:', error?.message || 'No data found');
          return null;
        }

        this.$patch({
          user: null,
          userEmail: email,
          currentProject: { id: projectId, role: data.role },
        });

        return data;
      } catch (error) {
        console.error('Error checking project member:', error.message);
        return null;
      }
    },

    setCurrentProject(project) {
      this.currentProject = project;
      this.saveProjectToLocalStorage(project); // Save current project to localStorage
      if (project && project.id) {
        this.fetchContacts(project.id).catch((error) => {
          console.error('Error fetching contacts for the new project:', error.message);
        });
      }
    },

    clearCurrentProject() {
      this.currentProject = null;
      this.contacts = []; // Clear contacts when no project is selected
    },

    clearSession() {
      this.$patch({
        user: null,
        userEmail: null,
        currentProject: null,
        liveTimecode: '00:00:00', // Clear the live timecode on logout
        contacts: [], // Clear contacts on logout
        currentTimeSource: 'device', // Reset time source on logout
        _intervalId: null, // Clear interval
        onlineStatus: navigator.onLine, // Reset online status
      });
      localStorage.removeItem('liveTimecode');
      localStorage.removeItem('currentTimeSource');
      localStorage.removeItem('userSession');
      localStorage.removeItem('currentProject');

      if (this._intervalId) {
        clearInterval(this._intervalId);
        this._intervalId = null;
      }

      // Clean up online status event listeners
      this.cleanupOnlineStatus();
    },

    async signOut() {
      try {
        const { error } = await supabase.auth.signOut();
        if (error) {
          throw new Error('Sign out failed');
        }
        this.clearSession(); // Clear the session in the store
      } catch (error) {
        console.error('Error signing out:', error.message);
        throw error;
      }
    },

    // **Live Timecode Management**
    setLiveTimecode(timecode) {
      this.liveTimecode = timecode;
      localStorage.setItem('liveTimecode', timecode);
    },

    incrementLiveTimecode() {
      let [hours, minutes, seconds] = this.liveTimecode.split(':').map(Number);
      seconds += 1;
      if (seconds >= 60) {
        seconds = 0;
        minutes += 1;
      }
      if (minutes >= 60) {
        minutes = 0;
        hours += 1;
      }
      this.setLiveTimecode(
        `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
      );
    },

    // **Contact Management Actions**
    async fetchContacts(projectId) {
      this.isLoading = true;
      try {
        const { data, error } = await supabase
          .from('project_contacts')
          .select('*')
          .eq('project_id', projectId);

        if (error) {
          console.error('Error fetching contacts:', error.message);
          throw error;
        }

        this.contacts = data;
      } catch (error) {
        console.error('Failed to fetch contacts:', error.message);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },

    async addContact(contact) {
      this.isSubmitting = true;
      try {
        const { data, error } = await supabase.from('project_contacts').insert([
          {
            project_id: this.currentProject.id,
            ...contact,
          },
        ]);

        if (error) {
          console.error('Error adding contact:', error.message);
          throw error;
        }

        this.contacts.push(data[0]);
      } catch (error) {
        console.error('Failed to add contact:', error.message);
        throw error;
      } finally {
        this.isSubmitting = false;
      }
    },

    async updateContact(contactId, updatedData) {
      this.isSubmitting = true;
      try {
        const { data, error } = await supabase
          .from('project_contacts')
          .update(updatedData)
          .eq('id', contactId);

        if (error) {
          console.error('Error updating contact:', error.message);
          throw error;
        }

        const index = this.contacts.findIndex((c) => c.id === contactId);
        if (index !== -1 && data.length > 0) {
          this.contacts[index] = { ...this.contacts[index], ...data[0] };
        }
      } catch (error) {
        console.error('Failed to update contact:', error.message);
        throw error;
      } finally {
        this.isSubmitting = false;
      }
    },

    async deleteContact(contactId) {
      this.isSubmitting = true;
      try {
        const { error } = await supabase
          .from('project_contacts')
          .delete()
          .eq('id', contactId);

        if (error) {
          console.error('Error deleting contact:', error.message);
          throw error;
        }

        this.contacts = this.contacts.filter((c) => c.id !== contactId);
      } catch (error) {
        console.error('Failed to delete contact:', error.message);
        throw error;
      } finally {
        this.isSubmitting = false;
      }
    },

    // **Time Source Management**
    initializeTimeSource() {
      const savedTimeSource = this.currentTimeSource || 'device';
      this.currentTimeSource = savedTimeSource;
      this.startTimeSource(this.currentTimeSource);
    },

    startTimeSource(source) {
      if (this._intervalId) {
        clearInterval(this._intervalId);
        this._intervalId = null;
      }

      switch (source) {
        case 'device':
          this.startDeviceTime();
          break;
        case 'custom':
          this.startCustomTimecode();
          break;
        case 'world':
          this.startWorldTime();
          break;
        default:
          this.startDeviceTime();
      }
    },

    setCurrentTimeSource(source) {
      if (!['device', 'custom', 'world'].includes(source)) {
        console.error(`Invalid time source: ${source}`);
        return;
      }
      this.currentTimeSource = source;
      localStorage.setItem('currentTimeSource', source);
      this.startTimeSource(source);
    },

    startDeviceTime() {
      const updateDeviceTime = () => {
        const now = new Date();
        const formattedTime = this.formatTimecode(
          now.getHours(),
          now.getMinutes(),
          now.getSeconds()
        );
        this.setLiveTimecode(formattedTime);
      };

      updateDeviceTime();
      this._intervalId = setInterval(updateDeviceTime, 1000);
    },

    startCustomTimecode(initialTime = '00:00:00') {
      if (this.liveTimecode === '00:00:00') {
        this.setLiveTimecode(initialTime);
      }

      this._intervalId = setInterval(() => {
        this.incrementLiveTimecode();
      }, 1000);
    },

    startWorldTime(offsetHours = 0) {
      const updateWorldTime = () => {
        const now = new Date();
        now.setUTCHours(now.getUTCHours() + offsetHours);
        const formattedTime = this.formatTimecode(
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds()
        );
        this.setLiveTimecode(formattedTime);
      };

      updateWorldTime();
      this._intervalId = setInterval(updateWorldTime, 1000);
    },

    formatTimecode(hours, minutes, seconds) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
        seconds
      ).padStart(2, '0')}`;
    },

    cleanupTimecode() {
      if (this._intervalId) {
        clearInterval(this._intervalId);
        this._intervalId = null;
      }
    },

    resetLiveTimecode() {
      this.setLiveTimecode('00:00:00');
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.user,
    getUserEmail: (state) => state.userEmail,
    getCurrentProject: (state) => state.currentProject,
    getLiveTimecode: (state) => state.liveTimecode,
    getOnlineStatus: (state) => state.onlineStatus,

    getCurrentTimeSourceLabel: (state) => {
      switch (state.currentTimeSource) {
        case 'device':
          return 'Device Time';
        case 'custom':
          return 'Custom Timecode';
        case 'world':
          return 'World Time (GMT)';
        default:
          return 'Unknown';
      }
    },

    getContacts: (state) => state.contacts,
    getIsLoading: (state) => state.isLoading,
    getIsSubmitting: (state) => state.isSubmitting,
  },
});
