// src/stores/darkModeStore.js
import { defineStore } from 'pinia';

export const useDarkModeStore = defineStore('darkMode', {
  state: () => ({
    isDarkMode: false,
  }),
  actions: {
    initializeDarkMode() {
      // Check localStorage for saved preference
      const savedPreference = localStorage.getItem('darkMode');
      if (savedPreference !== null) {
        this.isDarkMode = JSON.parse(savedPreference);
      } else {
        // Default to system preference
        this.isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      }
      this.applyDarkMode();
    },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      this.applyDarkMode();
      this.savePreference();
    },
    applyDarkMode() {
      if (this.isDarkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    },
    savePreference() {
      localStorage.setItem('darkMode', JSON.stringify(this.isDarkMode));
    },
  },
});
