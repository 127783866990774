<template>
<div id="app" :class="{ 'dark': isDarkMode }">
  <header class="header">
    <nav>
      <router-link to="/projects" class="project-link">
        View All Projects
      </router-link>
    </nav>
  </header>

  <!-- Main content area for routed components -->
  <main class="main-content">
    <router-view />
  </main>

  <!-- Internet Connection Status Section -->
  <section class="status-section">
    <p :class="onlineStatusClass">
      Internet Status: {{ onlineStatusText }}
      <span class="version-text">Version {{ appVersion }}</span>
    </p>
  </section>

  <!-- Footer Component -->
  <Footer
    :liveTimecode="liveTimecode"
    :currentTimeSourceLabel="currentTimeSourceLabel"
    :localStorageUsage="localStorageUsage"
    :userEmail="userEmail"
    :isAdmin="isAdmin"
    :isDarkMode="isDarkMode"
    @clearCache="clearCache"
    @signOut="signOut"
    @toggleDarkMode="toggleDarkMode"
  />
</div>
</template>

<script>
// Import your stores and components as before
import { useUserStore } from './stores/userStore';
import { useRouter } from 'vue-router';
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import Footer from './components/Footer.vue';
import { useDarkModeStore } from './stores/darkModeStore';
import { useToast } from 'vue-toastification';

export default {
components: {
  Footer,
},
setup() {
  const userStore = useUserStore();
  const router = useRouter();
  const darkModeStore = useDarkModeStore();
  const toast = useToast();

  const appVersion = '9.5';
  const userEmail = computed(() => userStore.getUserEmail);
  const isAdmin = computed(() => userStore.isAdmin);
  const onlineStatus = ref(navigator.onLine);
  const onlineStatusText = computed(() => (onlineStatus.value ? 'Online' : 'Offline'));
  const onlineStatusClass = computed(() => ({
    'online': onlineStatus.value,
    'offline': !onlineStatus.value,
  }));

  const liveTimecode = computed(() => userStore.liveTimecode);
  const currentTimeSourceLabel = computed(() => userStore.getCurrentTimeSourceLabel);

  const localStorageUsage = ref({
    used: 0,
    max: 5120, // 5MB in KB
  });

  const isDarkMode = computed(() => darkModeStore.isDarkMode);
  const toggleDarkMode = () => darkModeStore.toggleDarkMode();

  const calculateLocalStorageUsage = () => {
    let total = 0;
    for (const key in localStorage) {
      if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
        const value = localStorage.getItem(key);
        total += key.length + value.length;
      }
    }
    localStorageUsage.value.used = (total / 1024).toFixed(2);
  };

  const clearCache = () => {
    localStorage.clear();
    calculateLocalStorageUsage();
    toast.success('Cache cleared successfully!');
  };

  const fetchUserData = async () => {
    if (!userEmail.value) {
      await userStore.fetchUserSession();
      if (!userStore.isAuthenticated) {
        console.log('User is not authenticated, checking project_members');
      }
    }
  };

  const signOut = async () => {
    try {
      await userStore.signOut();
      router.push('/');
    } catch (error) {
      console.error('Error during sign-out:', error.message);
      toast.error('Error during sign-out. Please try again.');
    }
  };

  const initializeTimecode = () => userStore.initializeTimeSource();
  const updateOnlineStatus = () => onlineStatus.value = navigator.onLine;

  onMounted(() => {
    fetchUserData();
    initializeTimecode();
    calculateLocalStorageUsage();
    darkModeStore.initializeDarkMode();
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('online', updateOnlineStatus);
    window.removeEventListener('offline', updateOnlineStatus);
  });

  return {
    userEmail,
    isAdmin,
    signOut,
    onlineStatusText,
    onlineStatusClass,
    liveTimecode,
    currentTimeSourceLabel,
    localStorageUsage,
    clearCache,
    isDarkMode,
    toggleDarkMode,
    appVersion,
  };
},
};
</script>

<style scoped>
/* General layout for app */
html, body, #app {
height: 100%;
margin: 0;
font-family: Arial, sans-serif;
background-color: var(--bg-color);
color: var(--text-color);
}

#app {
display: flex;
flex-direction: column;
}

header.header {
background-color: #ffffff;
color: #333;
padding: 10px 20px;
border-bottom: 1px solid #ddd;
}

.dark header.header {
background-color: #1f2937;
color: #f8f9fa;
border-color: #444;
}

nav {
display: flex;
justify-content: space-between;
align-items: center;
}

.project-link {
text-decoration: none;
color: #007bff;
font-weight: bold;
}

.project-link:hover {
text-decoration: underline;
}

.main-content {
flex-grow: 1;
padding: 20px;
}

/* Status section */
.status-section {
background-color: #f1f3f5;
padding: 10px;
text-align: center;
}

.dark .status-section {
background-color: #2d3748;
}

.version-text {
display: block;
font-size: 12px;
color: #666;
}

.online {
color: green;
}

.offline {
color: red;
}

/* Dark mode variables */
:root {
--bg-color: #ffffff;
--text-color: #000000;
}

.dark {
--bg-color: #1f2937;
--text-color: #f8f9fa;
}

body {
background-color: var(--bg-color);
color: var(--text-color);
}
</style>
