// main.js
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import '@fortawesome/fontawesome-free/css/all.css';

// Import Vue Toastification and its CSS
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// Import the user store to load session on app start
import { useUserStore } from './stores/userStore';

// Optional: Customize default toast options
const toastOptions = {
  // You can set your default options here
  position: POSITION.TOP_RIGHT,
  timeout: 5000, // Duration in milliseconds
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
};

const app = createApp(App);
const pinia = createPinia();

// Register service worker if available
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.log('Service Worker registration failed:', error);
      });
  });
}

// Use Pinia and router in the app
app.use(pinia);
app.use(router);

// Use Toastification plugin with the specified options
app.use(Toast, toastOptions);

// Load user session and project from localStorage on app start
const userStore = useUserStore(pinia);
userStore.loadSessionFromLocalStorage(); // Load the saved session (if exists)
userStore.loadProjectFromLocalStorage(); // Load the saved project (if exists)

// Mount the app only after the session is restored
try {
  app.mount('#app');
} catch (error) {
  console.error('Error mounting the app:', error);
}
