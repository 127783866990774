<template>
<footer class="footer">
  <div class="container">
    <div class="footer-content">
      <!-- Left Column -->
      <div class="left-column">
        <center>
        <h3 class="title">Live Timecode</h3>
        <p class="time-source-label">
        Current Time Source: {{ currentTimeSourceLabel }}
      </p>
        <p class="timecode">{{ liveTimecode }}</p>
        <TimeSourceSelector />
        </center>
      </div>

      <!-- Right Column -->
      <div class="right-column">
        <div class="cache-usage">
          <h4 class="subtitle">Cache Usage</h4>
          <p>Used: {{ localStorageUsage.used }} KB</p>
          <p>Max Available: {{ localStorageUsage.max }} KB</p>
          <button @click="$emit('clearCache')" class="button clear-cache">
            Clear Cache
          </button>
        </div>

        <div v-if="userEmail" class="user-info">
          <p>
            You are logged in as: <strong>{{ userEmail }}</strong>
            <span v-if="isAdmin" class="admin-badge">(Admin)</span>
          </p>
          <button @click="$emit('signOut')" class="button sign-out">
            Sign Out
          </button>
        </div>
        <div v-else>
          <p>No user email available.</p>
        </div>

        <button @click="$emit('toggleDarkMode')" class="button toggle-mode">
          <span v-if="isDarkMode">🌞 Light Mode</span>
          <span v-else>🌜 Dark Mode</span>
        </button>
      </div>
    </div>
  </div>
</footer>
</template>

<script>
import TimeSourceSelector from './TimeSourceSelector.vue';

export default {
components: {
  TimeSourceSelector,
},
props: {
  liveTimecode: {
    type: String,
    required: true,
  },
  currentTimeSourceLabel: {
    type: String,
    required: true,
  },
  localStorageUsage: {
    type: Object,
    required: true,
  },
  userEmail: {
    type: String,
    default: null,
  },
  isAdmin: {
    type: Boolean,
    default: false,
  },
  isDarkMode: {
    type: Boolean,
    required: true,
  },
},
};
</script>

<style scoped>
.footer {
background-color: #f8f9fa; /* Light mode background */
color: #333; /* Light mode text */
padding: 20px 0;
border-top: 1px solid #ddd;
}

.dark .footer {
background-color: #333; /* Dark mode background */
color: #f8f9fa; /* Dark mode text */
border-color: #444;
}

.container {
max-width: 1200px;
margin: 0 auto;
padding: 0 20px;
}

.footer-content {
display: flex;
flex-direction: column;
gap: 20px;
justify-content: space-between;
}

@media (min-width: 768px) {
.footer-content {
  flex-direction: row;
}
}

.left-column,
.right-column {
flex: 1;
}

.title {
font-size: 18px;
font-weight: bold;
}

.timecode {
font-family: monospace;
font-size: 24px;
margin: 10px 0;
}

.time-source-label {
font-size: 14px;
color: #666;
}

.cache-usage {
margin-bottom: 15px;
}

.subtitle {
font-size: 16px;
font-weight: bold;
}

.user-info {
margin-bottom: 10px;
}

.admin-badge {
font-size: 12px;
color: green;
}

.button {
display: inline-block;
padding: 10px 20px;
margin-top: 10px;
border: none;
border-radius: 4px;
font-size: 14px;
cursor: pointer;
}

.clear-cache {
background-color: #f0ad4e;
color: white;
}

.clear-cache:hover {
background-color: #ec971f;
}

.sign-out {
background-color: #d9534f;
color: white;
}

.sign-out:hover {
background-color: #c9302c;
}

.toggle-mode {
background-color: #5bc0de;
color: white;
}

.toggle-mode:hover {
background-color: #46b8da;
}
</style>
